import React, { createContext, useContext, useState, useEffect } from 'react'
import PropTypes from 'prop-types'
//import { fetchTokenNumbers } from 'mapi-js'

const formatPhone = n =>
  `1-${n.substring(0, 3)}-${n.substring(3, 6)}-${n.substring(6, 10)}`

const defaultNumber = formatPhone('8556555458')

export const MapiContext = createContext()

export const useMapi = () => useContext(MapiContext)

export const MapiProvider = ({ children }) => {
  const [rotatedNumber, setRotatedNumber] = useState(defaultNumber)

  useEffect(() => {
    /*fetchTokenNumbers(['JE'])
      .then(data => {
        setRotatedNumber(formatPhone(data.tokens.JE))
      })
      // eslint-disable-next-line no-console
      .catch(error => console.log(error)) */
  })

  return (
    <MapiContext.Provider value={{ rotatedNumber }}>
      {children}
    </MapiContext.Provider>
  )
}

MapiProvider.propTypes = {
  children: PropTypes.node.isRequired,
}
