import React from 'react'
import PropTypes from 'prop-types'
import { globalHistory } from '@reach/router'
//import { bootstrap } from 'mapi-js'

import Wrapper from './src/components/Wrapper'

// wrapRootElement is part of the Gatsby API, and thus cannot be the default export
/* eslint-disable import/prefer-default-export, react/jsx-filename-extension */
export const wrapRootElement = ({ element }) => <Wrapper>{element}</Wrapper>

wrapRootElement.propTypes = {
  element: PropTypes.node.isRequired,
}

export const onInitialClientRender = () => {
  // @TODO no longer serving rotated numbers for JE, delete this.
  globalHistory._onTransitionComplete() // eslint-disable-line no-underscore-dangle
  const mapiConfig = {
    brand: 'JE',
    forceDomain: 'justenergy.com',
    defaultPhone: '1-866-288-3105',
    allowLastResortNumber: false,
    replacement: [
      {
        selectors: '[data-phone-rotation="true"]',
        formatter: 'USAddOne',
      },
    ],
    defaultPromoCode: 146694,
  }

  // bootstrap(mapiConfig)
}
