import React from 'react'
import PropTypes from 'prop-types'
import merge from 'lodash.merge'
import { css, Global } from '@emotion/core'
import { ThemeProvider as EmotionThemeProvider } from 'emotion-theming'

import defaultTheme, { makeColors } from '../utils/theme'

const ThemeProvider = ({ theme: overwritingTheme, children }) => {
  let theme = defaultTheme

  if (overwritingTheme) {
    theme = merge(
      defaultTheme,
      overwritingTheme,
      overwritingTheme.colors && {
        colors: makeColors(overwritingTheme.colors),
      }
    )
  }

  const GlobalStyles = () => (
    <Global
      styles={css`
        html {
          box-sizing: border-box;
          font-family: ${theme.fonts.body.family};
        }

        * {
          box-sizing: inherit;

          &::before,
          &::after {
            box-sizing: inherit;
          }

          &:focus {
            outline: 1px dotted ${theme.colors.primary.base.hex};
            outline-offset: ${theme.spacing.min};
          }

          &::-moz-focus-inner {
            border: 0;
          }
        }

        a {
          &:link {
            color: ${theme.colors.primary.base.hex};
          }

          &:hover {
            color: ${theme.colors.primary.dark.hex};
            text-decoration: underline;
          }

          &:active {
            color: ${theme.colors.primary.darker.hex};
            text-decoration: underline;
          }

          &:visited {
            color: ${theme.colors.secondary.base.hex};
          }
        }

        h1,
        h2,
        h3,
        h4,
        h5,
        h6 {
          margin-top: 0;
          margin-bottom: 0.5em;
          font-family: ${theme.fonts.headings.family};
        }

        input,
        select,
        textarea,
        button {
          font-size: inherit;
        }

        body,
        ol,
        ul,
        blockquote,
        figure {
          margin: 0;
          padding: 0;
        }

        label {
          display: block;
        }

        img,
        em,
        iframe,
        object,
        audio,
        video {
          max-width: 100%;
          height: auto;
        }
      `}
    />
  )

  return (
    <EmotionThemeProvider theme={theme}>
      <React.Fragment>
        <GlobalStyles />
        {children}
      </React.Fragment>
    </EmotionThemeProvider>
  )
}

ThemeProvider.propTypes = {
  theme: PropTypes.object, // eslint-disable-line
  children: PropTypes.node.isRequired,
}

export default ThemeProvider
