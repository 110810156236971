import React from 'react'
import Helmet from 'react-helmet'
import PropTypes from 'prop-types'
import { withTheme } from 'emotion-theming'
import { css, Global } from '@emotion/core'

import ThemeProvider from './ThemeProvider'
import jeTheme from '../theme'
import { MapiProvider } from '../context/mapi'

import 'typeface-muli'

const GlobalStyles = withTheme(({ theme }) => (
  <Global
    styles={css`
      body {
        color: ${theme.colors.dark.base.hex};
      }

      // The Enrollment App tries to hide the footer sometimes (?) but is actually hiding the main element.  Since we
      // have mounted the Enrollment App inside of main, this ends up hiding the App itself, making the page appear to
      // be broken.
      main {
        display: block !important;
      }

      main > section {
        position: relative;
        background-color: ${theme.colors.light.lighter.hex};

        &:nth-of-type(odd) {
          background-color: ${theme.colors.light.base.hex};
        }
      }
    `}
  />
))

const Wrapper = ({ children }) => (
  <ThemeProvider theme={jeTheme}>
    <Helmet>
      <meta
        name="google-site-verification"
        content="kTn6HeRVOChG7n41TUBXiNTVUgp7sW0yqC4_DNn_oeA"
      />
      <meta name="ahrefs-site-verification" content="b0058799a5e5cfa8bf9351b772fbc0bf8acc4a5815b83fde7535f1d508696a8d" />
    </Helmet>
    <GlobalStyles />
    <MapiProvider>{children}</MapiProvider>
  </ThemeProvider>
)

Wrapper.defaultProps = {
  theme: {},
}

Wrapper.propTypes = {
  theme: PropTypes.object, // eslint-disable-line
  children: PropTypes.node.isRequired,
}

export default Wrapper
