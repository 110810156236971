module.exports = {
  colors: {
    primary: '#002E6D',
    secondary: '#1990FF',
    tertiary: '#FCA500',
    action: '#002E6D',
    dark: '#42535C',
    light: '#f2f2f2',
  },
  fonts: {
    body: {
      family: "'Muli', sans-serif",
    },
    headings: {
      family: "'Muli', sans-serif",
      lg: {
        weight: 400,
      },
      md: {
        weight: 300,
      },
      sm: {
        weight: 300,
      },
    },
    subheadings: {
      lg: {
        size: [20, 24],
        lineHeight: [22, 32],
        weight: 400,
      },
      md: {
        weight: 300,
      },
      sm: {
        weight: 300,
      },
    },
  },
}
